import React from 'react';
import { AuthService } from '../services';

const withAuth = (AuthComponent: any) => {
    const Auth = new AuthService();

    return class AuthWrapped extends React.Component<any, any> {
        constructor(props: any) {
            super(props);
            this.state = {
                user: null,
            };
        }

        public async componentWillMount() {
            if (!Auth.loggedIn()) {
                if (Auth.isTokenExpired() && Auth.getRefresh()) {
                    try {
                        await Auth.refreshToken();
                    } catch {
                        Auth.logout();
                        this.props.history.replace('/login');
                    }
                } else {
                    this.props.history.replace('/login');
                    return;
                }
            }

            try {
                const profile = Auth.getProfile();
                this.setState({
                    user: profile,
                });
            } catch (err) {
                Auth.logout();
                this.props.history.replace('/login');
            }
        }

        public render() {
            if (this.state.user) {
                return (<AuthComponent user={this.state.user} {...this.props} />);
            }
                return null;

        }
    };
};

export default withAuth;
