import axios from 'axios';
import config from '../utils/config';
import AuthService from './AuthService';
import { async } from 'q';

export default class ApiService {
  public client: any;
  private auth: AuthService;

  constructor() {
    this.auth = new AuthService();

    this.client = axios.create({
      baseURL: config.API_ENDPOINT,
    });

    this.client.interceptors.request.use(
      async (reqConfig: any) => {

        if (this.auth.loggedIn()) {
          reqConfig.headers['Authorization'] = `Bearer ${this.auth.getToken()}`;
        } else if (this.auth.isTokenExpired() && this.auth.getRefresh()) {
          console.log('refreshing from interceptor ......');
          await this.auth.refreshToken();
          reqConfig.headers['Authorization'] = `Bearer ${this.auth.getToken()}`;
        }
        return reqConfig;
      },
      (err: any) => Promise.reject(err)
    );

    this.client.interceptors.response.use((response: any) => response.data);
  }
}
