import decode from 'jwt-decode';
import axios from 'axios';
import config from '../utils/config';
import { ApiService, StorageService } from './';
import { IProfile } from '../interfaces';

const { API_ENDPOINT,
        ID_TOKEN, ID_REFRESH_TOKEN } = config;

export interface Props {
    children?: React.ReactNode;
}

export default class AuthService {
    // Initializing important variables
    public storage = new StorageService();

    public async login(username: string, password: string): Promise<any> {
        // Get a token from api server using axios
        const url = `${API_ENDPOINT}/token/`;
        const data = { username, password };

        const res = await axios
            .post(url, data);
        this.setToken(res.data.access); // Setting the token in storage
        this.setRefresh(res.data.refresh);
        return Promise.resolve(res);
    }

    public async refreshToken() {
        const url = `${API_ENDPOINT}/token/refresh/`;
        const data = { refresh : this.getRefresh() };

        const res = await axios
            .post(url, data);
        this.setToken(res.data.access); // Setting the token in storage
        if (res.data.refresh) {
            this.setRefresh(res.data.refresh);
        } else {
            this.storage.delete(ID_REFRESH_TOKEN);
        }
        return Promise.resolve(res);

    }

    public loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    public isTokenExpired(token: string = this.getToken()) {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            return false;
        } catch (err) {
            return false;
        }
    }

    public setToken(idToken: string) {
        // Saves user token to localStorage
        this.storage.set(ID_TOKEN, idToken);
    }

    public setRefresh(refresh: string) {
        // Saves refresh to localStorage
        this.storage.set(ID_REFRESH_TOKEN, refresh);
    }

    public getToken(): string {
        // Retrieves the user token from localStorage
        return this.storage.get(ID_TOKEN);
    }

    public getRefresh(): string {
        // Retrieves the refresh from localStorage
        return this.storage.get(ID_REFRESH_TOKEN);
    }

    public logout() {
        // Clear user token and profile data from localStorage
        this.storage.clear();
    }

    public getProfile(): IProfile {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    public fetch(url: string, options: any) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': '',
        };

        // Setting Authorization header
        // Authorization: Token xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = `Token ${this.getToken()}`;
        }

        return axios(url, {
            headers,
            ...options,
        })
            .then(this.checkStatus)
            .then(response => response.json());
    }

    public checkStatus(response: any) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        }
        throw new Error(response);
    }
}
