import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  root: {
    flexGrow: 1,
  },
};

export interface Props {
  children?: React.ReactNode;
  classes: any;
  timer: any;
}

class Progress extends React.Component<Props, any> {
  public timer: any;
  constructor(props: any) {
    super(props);
    this.state = {
      completed: 0,
    };
  }

  public componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  public componentWillUnmount() {
    clearInterval(this.timer);
  }

  public progress = () => {
    const { completed } = this.state;
    if (completed === 100) {
      this.setState({ completed: 0 });
    } else {
      const diff = Math.random() * 10;
      this.setState({ completed: Math.min(completed + diff, 100) });
    }
  }

  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <LinearProgress variant='determinate' color='secondary' value={this.state.completed} />
      </div>
    );
  }
}

export default withStyles(styles)(Progress);
