import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const styles: any = (theme: any) => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

export interface Props {
  children?: React.ReactNode;
  classes: any;
  timer: any;
  open: boolean;
  message: string;
}

class SnackBar extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
    };
  }

  private handleClick = () => {
    this.setState({ open: true });
  }

  private handleClose = (event: any) => {
    this.setState({ open: false });
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open });
    }
  }

  public render() {
    const { classes, message } = this.props;
    return (
      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>{message}</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={this.handleClose}
              className={classes.close}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
    );
  }
}

export default withStyles(styles)(SnackBar);
