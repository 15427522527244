import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Question, Lecture, withAuth } from '../../components';

import './Lesson.scss';
import { LessonService } from '../../services';
import { CircularProgress } from '@material-ui/core';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  timer: any;
  lessons: any;
  match: any;
  showLoader: any;
  hideLoader: any;
  navigate: any;
}

const styles = (theme: any) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class Lesson extends React.Component<Props, any> {
  public timer: any;
  private lessonService: LessonService = new LessonService();
  constructor(props: any) {
    super(props);
    this.state = {
      completed: 0,
    };
  }

  public async componentDidMount() {
    const { match: { params: { lessonId } } } = this.props;
    this.loadLesson(lessonId);
    this.refreshLink(lessonId);
  }

  private refreshLink = (id: any) => {
    const elements = document.getElementsByClassName('lesson-active');
    [].forEach.call(elements, (el: HTMLElement) => {
      el.classList.remove('lesson-active');
    });

    const elm = document.getElementById(`link-${id}`);
    if (elm) {
      elm.classList.add('lesson-active');
    }
  }

  public componentWillReceiveProps(nextProps: any) {
    const { match: { params } } = this.props;

    const oldLessonId = params.lessonId;
    const newLessonId = nextProps.match.params.lessonId;

    const idExist = (oldLessonId && newLessonId) && (oldLessonId !== newLessonId);
    if (idExist) {
      this.loadLesson(newLessonId);
    }

    this.refreshLink(newLessonId);
  }

  private async loadLesson(lessonId: number) {
    this.props.showLoader();
    this.setState({ lesson: null });
    const lesson = await this.lessonService.getOne(lessonId);
    this.setState({ lesson });
    this.props.hideLoader();
  }

  public render() {
    const { lesson } = this.state;
    const { lessons, classes } = this.props;

    if (!lesson || !lesson.unit_object) {
      return (
        <div className='Lesson'>
          <div className='Loader'>
            <CircularProgress size={50} className={classes.progress} />
          </div>
        </div>
      );
    }

    const { unit_object } = lesson;
    unit_object.name = lesson.name;

    return (
      <div className='Lesson'>
        {unit_object.question_type && <Question lessons={lessons} lesson={lesson} question={unit_object} />}
        {unit_object.lecture_type && <Lecture lessons={lessons} lesson={lesson} lecture={unit_object} />}
      </div>
    );
  }
}

export default withAuth(withStyles(styles)(Lesson));
