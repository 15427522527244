import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { CourseService } from '../../services';
import { SnackBar, Progress } from '../';
import { ICourse } from '../../interfaces';

const styles: any = (theme: any) => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

function transition(props: any) {
    return <Slide direction='up' {...props} />;
}

export interface Props {
    children?: React.ReactNode;
    classes: any;
    timer: any;
    course?: ICourse;
    send?: any;
}

class AddCourse extends React.Component<Props, any> {
    private courseService = new CourseService();
    private baseState: any;
    constructor(props: any) {
        super(props);
        const { course } = this.props;
        this.state = {
            name: course ? course.name : '',
            short_description: course ? course.short_description : '',
            objectives: course ? course.objectives : '',
            description: course ? course.description : '',
            hero_image: course && course.hero_image ? course.hero_image : '',
            errorMsg: '',
            open: false,
            disableButton: false,
            openSnackBar: false,
            showLoader: false,
        };
        this.baseState = this.state;
        this.handleChange = this.handleChange.bind(this);
        this.addCourse = this.addCourse.bind(this);
    }

    public handleOpen = () => {
        this.setState({ open: true });
    }

    public handleClose = () => {
        this.setState(this.baseState);
    }

    private handleChange(e: any) {
        this.setState({ [e.target.name]: e.target.value });
    }

    public addCourse = (event: any) => {
        event.preventDefault();
        const { name, description, hero_image, short_description, objectives } = this.state;
        const { course } = this.props;
        this.setState({ disableButton: true, showLoader: true, openSnackBar: false });

        if (course) {
            let errorMsg = 'Cannot add course';
            course.name = name;
            course.description = description;
            course.hero_image = hero_image;
            course.short_description = short_description;
            course.objectives = objectives;

            this.courseService
                .update(course)
                .then(res => {
                    this.props.send(res);
                    errorMsg = 'Course Added';
                    setTimeout(() => {
                        this.handleClose();
                    }, 1500);
                })
                .finally(() => {
                    this.setState({ errorMsg, disableButton: false, openSnackBar: true, showLoader: false });
                });

        } else {
            let errorMsg = 'Cannot Update Course';
            this.courseService
                .add({ name, description, hero_image, objectives, short_description })
                .then(res => {
                    this.props.send(res);
                    errorMsg = 'Course Updated';
                    setTimeout(() => {
                        this.handleClose();
                    }, 1500);
                })
                .finally(() => {
                    this.setState({ errorMsg, disableButton: false, openSnackBar: true, showLoader: false });
                });
        }
    }

    public render() {
        const { classes, course } = this.props;
        const { disableButton, openSnackBar, showLoader, objectives, short_description } = this.state;
        return (
            <div className='AddCourse'>
                <Button onClick={this.handleOpen} variant={course ? 'outlined' : 'contained'} color={course ? 'secondary' : 'primary'} className={classes.button}>
                    {!course && <AddIcon />} { course ? 'Edit' : 'Course' }
                </Button>

                <SnackBar open={openSnackBar} message={course ? 'Course Updated' : 'New Course Added'} />

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={transition}
                >
                    <form className={classes.container} onSubmit={this.addCourse}>
                    {showLoader && <Progress />}
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.handleClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='h6' color='inherit' className={classes.flex}>
                                    {course ? 'Update' : 'New'} Course
                                </Typography>
                                <Button disabled={disableButton} color='inherit' type='submit'>
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            <ListItem>
                                <TextField
                                    required
                                    label='Course Name'
                                    className={classes.textField}
                                    name='name'
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    autoComplete='name'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    label='Hero Image'
                                    className={classes.textField}
                                    name='hero_image'
                                    value={this.state.hero_image}
                                    onChange={this.handleChange}
                                    autoComplete='picture'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    label='Short Description'
                                    className={classes.textField}
                                    name='short_description'
                                    value={this.state.short_description}
                                    onChange={this.handleChange}
                                    autoComplete='short description'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    required
                                    label='Course Description'
                                    name='description'
                                    autoComplete='description'
                                    onChange={this.handleChange}
                                    multiline
                                    rows='4'
                                    value={this.state.description}
                                    className={classes.textField}
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    required
                                    label='Objectives'
                                    name='objectives'
                                    autoComplete='objectives'
                                    onChange={this.handleChange}
                                    multiline
                                    rows='4'
                                    value={this.state.objectives}
                                    className={classes.textField}
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                        </List>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddCourse);
