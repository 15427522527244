import React from 'react';
import { RouteComponentProps, withRouter, Redirect, Link } from 'react-router-dom';
import { IQuestion, IAnswer, Dictionary, ILesson } from '../../interfaces';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import './Question.scss';
import { ProgressService } from '../../services';
import { Alert } from '..';

const styles = (theme: any) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  hint: {
    padding: theme.spacing.unit * 2,
    color: 'rgba(0,0,0,0.6)',
  },
});

export interface Props extends RouteComponentProps<any> {
  children?: React.ReactNode;
  question: IQuestion;
  classes: any;
  lesson: ILesson;
  lessons?: any;
}

class Question extends React.Component<Props, any> {
  private ps = new ProgressService();
  constructor(props: Props) {
    super(props);
    this.state = {
      change: false,
      tfValue: null,
      scValue: null,
      mcValue: {},
      tCount: 0,
      showHint: false,
      bqValue: null,
      openWrong: false,
      openCorrect: false,
      openUnitComplete: false,
    };
  }

  private handleTFChange = (event: any) => {
    this.setState({ change: true, tfValue: event.target.value });
  }

  private handleBQChange = (event: any) => {
    this.setState({ bqValue: event.target.value });
  }

  private handleSCChange = (event: any) => {
    this.setState({ change: true, scValue: event.target.value });
  }

  private handleMCChange = (name: any) => (event: any) => {
    const { mcValue } = this.state;
    mcValue[name] = event.target.checked;
    this.setState({ mcValue, change: true });
  }

  public componentDidMount() {
    this.initAnswer();
  }

  private submitAnswer = async () => {
    const { lesson, question: { id, question_type } } = this.props;
    let answer = undefined;
    let change = this.state.change;
    this.setState({ tCount: this.state.tCount + 1 });

    if (question_type === 'BQ') {
      answer = this.state.bqValue;
      change = true;
    }

    if (question_type === 'MC') {
      const { mcValue } = this.state;
      answer = [];
      for (const key in mcValue) {
        if (mcValue[key]) {
          answer.push(parseInt(key));
        }
      }
    }

    if (question_type === 'SC') {
      answer = [parseInt(this.state.scValue)];
    }

    if (question_type === 'TF') {
      answer = [parseInt(this.state.tfValue)];
    }

    if (!change || !answer || answer.length === 0) {
      // send error msg
      this.setState({ openWrong: true });
      return;
    }

    const payload = {
      course_id: lesson.course,
      unit_id: lesson.unit,
      lesson_id: lesson.id,
      answer: JSON.stringify(answer),
      question_id: id,
    };

    try {
      const res = await this.ps.submitAnswer(payload);
      this.setState({ openCorrect: true });
      if (res.id) {
        // setTimeout(() => {
        //   this.props.history.push(`/courses/${res.course}/units/${res.unit}/lessons/${res.id}`);
        // }, 2000);
      }
      // end of the course
    } catch (err) {
      this.setState({ openWrong: true });
      console.log(err);
      // error msg
    }
  }

  private handleClose = (type: string) => {
    const { lesson, lessons } = this.props;
    const next_lesson = lessons[lessons.map((e: any) => e.id).indexOf(lesson.id) + 1];
    if (type === 'wrong') {
      this.setState({ openWrong: false });
    } else if (type === 'finish') {
      this.setState({ openUnitComplete: false });
      this.props.history.push(`/courses/${lesson.course}`);
    } else {
      this.setState({ openCorrect: false });
      if (next_lesson && next_lesson.course) {
        this.props.history.push(`/courses/${next_lesson.course}/units/${next_lesson.unit}/lessons/${next_lesson.id}`);
      } else {
        this.setState({ openUnitComplete: true });
      }
    }
  }
  private getHint = () => {
    this.setState({ showHint: true });
  }

  private initAnswer() {
    const { question } = this.props;
    const { question_type, answers } = question;

    console.log(question);

    const mcValue: Dictionary<boolean> = {};
    answers.forEach((item: any) => {
      mcValue[item.id.toString()] = false;
    });

    switch (question_type) {
      case 'TF': this.setState({ tfValue: answers[0].id }); break;
      case 'SC': this.setState({ scValue: answers[0].id }); break;
      case 'MC': this.setState({ mcValue }); break;
      case 'BQ': this.setState({ bqValue: '' }); break;
      default: break;
    }

  }

  public render() {
    const { classes, question, lesson, lessons } = this.props;
    const { tfValue, mcValue, scValue, bqValue, openWrong, openCorrect, tCount, showHint, openUnitComplete } = this.state;
    const { content, question_type, answers } = question;
    const next_lesson = lessons[lessons.map((e: any) => e.id).indexOf(lesson.id) + 1];

    let prev_lesson;
    try {
      prev_lesson = lessons[lessons.map((e: any) => e.id).indexOf(lesson.id) - 1];
    } catch {
      prev_lesson = undefined;
    }

    return (
      <div className='Question'>

        <Dialog
          open={openCorrect}
          onClose={(e) => this.handleClose('correct')}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>

          <DialogTitle id='alert-dialog-title' color='secondary'>Correct Answer</DialogTitle>

          <DialogActions>
            <Button onClick={(e) => this.handleClose('correct')} color='primary' autoFocus>
              Ci Gaba
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openUnitComplete}
          onClose={(e) => this.handleClose('finish')}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>

          <DialogTitle id='alert-dialog-title' color='secondary'>Jinjina!!!</DialogTitle>

          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Madalla da samin nasarar kammala darussan wannan shashe (Unit)
          </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={(e) => this.handleClose('finish')} color='primary' autoFocus>
              Kammala
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openWrong}
          onClose={(e) => this.handleClose('wrong')}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>

          <DialogTitle id='alert-dialog-title' color='secondary'>Incorrect Answer</DialogTitle>

          <DialogActions>
            {question.hint && tCount > 2 &&
              <Button style={{ float: 'left', textTransform: 'lowercase' }} color='secondary' onClick={this.getHint}>
                nemi taimako
            </Button>}
            <Button onClick={(e) => this.handleClose('wrong')} color='primary' autoFocus>
              Try Again
            </Button>
          </DialogActions>

          {question.hint && tCount > 2 && showHint &&
            <div className='hint'>
              <Typography component='p' align='left' className={classes.hint} style={{ textTransform: 'uppercase' }}>
                {question.hint}
              </Typography>
            </div>
          }
        </Dialog>

        <div className='_question-block'>
          <div className='block'>
            {content}
          </div>

          <div className='answer'>
            {question_type === 'BQ' &&
              <div className='_bq'>
                <TextField
                  id='outlined-multiline-static'
                  label='Rubuta Amsa'
                  multiline
                  rows='4'
                  onChange={this.handleBQChange}
                  className={classes.textField}
                  margin='normal'
                  variant='outlined'
                />
              </div>}

            {question_type === 'TF' &&
              <div className='_tf'>
                <RadioGroup
                  aria-label={content}
                  name='answer'
                  className={classes.group}
                  value={tfValue}
                  onChange={this.handleTFChange}
                >
                  {answers.map((ans: any) => (
                    <FormControlLabel key={ans.id} value={ans.id.toString()} control={<Radio color='primary' />} label={ans.content === 'answer_false' ? 'False' : 'True'} />
                  ))}
                </RadioGroup>
              </div>}

            {question_type === 'SC' &&
              <div className='_sc'>
                <RadioGroup
                  aria-label={content}
                  name='answer'
                  className={classes.group}
                  value={scValue}
                  onChange={this.handleSCChange}
                >
                  {answers.map((ans: any) => (
                    <FormControlLabel key={ans.id} value={ans.id.toString()} control={<Radio color='primary' />} label={ans.content} />
                  ))}
                </RadioGroup>
              </div>}

            {question_type === 'MC' && mcValue &&
              <div className='_mc'>
                <FormGroup>
                  {answers.map((ans: any) => (
                    <FormControlLabel key={ans.id}
                      control={
                        <Checkbox checked={mcValue[ans.id.toString()]} onChange={this.handleMCChange(ans.id)} value={ans.id.toString()} />
                      }
                      label={ans.content}
                    />))}
                </FormGroup>
              </div>}

          </div>
        </div>

        <div className='submit'>
          {prev_lesson &&
            <Link to={`/courses/${prev_lesson.course}/units/${prev_lesson.unit}/lessons/${prev_lesson.id}`}>
              <Button color='primary' style={{ float: 'left' }} className={classes.button}>
                Koma darasi na baya
            </Button>
            </Link>}

          <Button variant='contained' size='large' color='primary' style={{ float: 'right' }} className={classes.margin} onClick={this.submitAnswer}>
            Tura
            </Button>
        </div>

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Question));
