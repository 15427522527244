import decode from 'jwt-decode';
import axios from 'axios';
import config from '../utils/config';
import { ApiService, StorageService, AuthService } from './';
import { IProfile } from '../interfaces';

const { API_ENDPOINT } = config;

export default class ProfileService {
    // Initializing important variables
    private Auth = new AuthService();
    private API = new ApiService();
    private URL = `${API_ENDPOINT}/users/`;
    private profile = this.Auth.getProfile();

    public async getOne(id: string): Promise<IProfile> {
        const res = await this.API.client.get(`${this.URL}${id}/`);
        return Promise.resolve(res);
    }

    public async update(payload: any): Promise<IProfile> {
        const res = await this.API.client
            .put(`${this.URL}${this.profile.user_id}/`, payload);
        return Promise.resolve(res);
    }
}
