import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AlarmIcon from '@material-ui/icons/Alarm';
import PhoneLinkIcon from '@material-ui/icons/Phonelink';
import PublicIcon from '@material-ui/icons/Public';

import { makeStyles } from '@material-ui/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DraftsIcon from '@material-ui/icons/Drafts';
import StopIcon from '@material-ui/icons/Stop';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ViewListIcon from '@material-ui/icons/ViewList';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService, CourseService } from '../../services';

import topLandingBg from '../../assets/images/landing_bg_1.jpg';
import landingBg1 from '../../assets/images/section_bg_1.jpg';
import landingBg2 from '../../assets/images/section_bg_2.jpg';
import landingBg3 from '../../assets/images/section_bg_3.jpg';
import landingBg4 from '../../assets/images/section_bg_4.jpg';
import landingBg5 from '../../assets/images/section_bg_5.jpg';

import './PreviewCourse.scss';
import { Header, Footer, Progress, withAuth } from '../../components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IUnit, ILesson } from '../../interfaces';
import Loader from './Loader';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  timer: any;
  lessons: any;
  match: any;
  showLoader: any;
  hideLoader: any;
  navigate: any;
}

const styles: any = (theme: any) => {
  return ({
    root: {
      width: '100%',
      maxWidth: 460,
      backgroundColor: theme.palette.background.paper,
    },
    main: {
      marginTop: theme.spacing.unit * 15,
    },
    bgPic: {
      backgroundImage: `url(${topLandingBg})`,
    },
    branding: {
      justifyContent: 'center',
    },
    brandingIcon: {
      fontSize: '100px',
      color: 'red',
    },
    landingSec1: {
      backgroundImage: `url(${landingBg1})`,
    },
    landingSec2: {
      backgroundImage: `url(${landingBg2})`,
    },
    landingSec3: {
      backgroundImage: `url(${landingBg3})`,
    },
    landingSec4: {
      backgroundImage: `url(${landingBg4})`,
    },
    landingSec5: {
      backgroundImage: `url(${landingBg5})`,
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4,
    },
  });
};

class PreviewCourse extends React.Component<Props, any> {
  public auth = new AuthService();
  private CourseService = new CourseService();
  constructor(props: Props) {
    super(props);
    this.state = {
      loginError: false,
      showProgress: false,
      open: {},
    };
  }

  public componentWillMount() {
    const { match: { params: { id } } } = this.props;
    this.loadCourse(id);
  }

  public componentWillReceiveProps(nextProps: any) {
    const { match: { params } } = this.props;

    const oldId = params.id;
    const newId = nextProps.match.params.id;

    const idExist = (oldId && newId) && (oldId !== newId);
    if (idExist) {
      this.loadCourse(newId);
    }
  }

  private async loadCourse(id: number) {
    this.setState({ showProgress: true });
    const course = await this.CourseService.getPreview(id);
    this.setState({ course, showProgress: false });
    console.log(course);
    this.props.hideLoader();
  }

  private handleClopseListitem = (id: number) => {
    const open: any = this.state.open;
    open[id] = !open[id];
    this.setState({ open });
  }

  public render() {
    const { classes } = this.props;
    const { course, showProgress, open } = this.state;

    const tempImage = 'https://www.cisl.cam.ac.uk/executive-education/learn-online/images/bespoke-online-learning.jpg';
    let previewImage = null;
    let shortDesc = null;
    let units = null;
    if (course) {
      previewImage = course.hero_image ? course.hero_image : tempImage;
      shortDesc = course.short_description ? course.short_description : course.description.substring(0, 128);

      units = course.units.map((unit: IUnit) => (
        <div key={unit.id}>
          <ListItem button onClick={(e) => { this.handleClopseListitem(unit.id); }}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText inset primary={unit.name} />
            {open[unit.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[unit.id]} timeout='auto' unmountOnExit>
            <List disablePadding>
              {unit.lessons.map((lesson: ILesson) => (
                <ListItem button className={classes.nested} key={lesson.id}>
                  <ListItemIcon>
                    <StopIcon />
                  </ListItemIcon>
                  <ListItemText inset primary={lesson.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ));
    }

    return (
      <main className='PreviewCourse'>
        {showProgress && <Progress />}
        <Header />

        {course ?
        <div className={`top-box`} style={{ backgroundImage: `url(${previewImage})` }}>
          <Typography variant='h2' color='inherit'>
            {course.name}
          </Typography>

          <div className='subtitle'>
            <Typography variant='body1' color='inherit'>
              {shortDesc}
            </Typography>
          </div>

          {course.active &&
          <Link to={`/courses/${course.id}`}><Button variant='contained' color='primary'>Fara Darasi</Button></Link>}
          
          {!course.active &&
          <Typography variant='h4' color='primary' >Comming Soon</Typography>}

        </div>
        :
        <Loader />
        }

        {course &&
        <Grid container direction='row' justify='center' alignItems='center' spacing={24} className={'block-info'}>
          <Grid item md={9} sm={12}>
            <Typography variant='h4' color='inherit'>
              Menene Wannan Darussa suka kunsa?
            </Typography>
            <Typography variant='body1' color='inherit'>
              {course.description}
            </Typography>
          </Grid>
        </Grid>}

        {course &&
        <Grid container direction='row' justify='center' alignItems='center' spacing={24} className={'block-info'}>
          <Grid item md={9} sm={12}>
            <Typography variant='h4' color='inherit'>
              Abubuwan dake ciki (darussa)
            </Typography>

            <List component='nav' className={classes.root}>
              { units }
            </List>
          </Grid>
        </Grid>}

        <Footer />
      </main>
    );
  }
}

export default withStyles(styles)(PreviewCourse);
