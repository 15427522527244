const getBaseEndpoint = () => {
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return 'http://0.0.0.0:8000/api/v1';
  }
  return 'https://atktut-dev.herokuapp.com/api/v1';
};

export default Object.freeze({
  SECRET: 'hafoip98398y4903t7y5t8wo75tw897o5thoualg8o47g38o',
  API_ENDPOINT: process.env.API || getBaseEndpoint(),
  NAMESPACE: 'koyi.atakaice',
  ID_TOKEN: 'id_Token',
  ID_REFRESH_TOKEN: 'id_refresh_token',
});
