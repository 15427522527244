import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AlarmIcon from '@material-ui/icons/Alarm';
import PhoneLinkIcon from '@material-ui/icons/Phonelink';
import PublicIcon from '@material-ui/icons/Public';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService } from '../../services';

import topLandingBg from '../../assets/images/landing_bg_1.jpg';
import landingBg1 from '../../assets/images/section_bg_1.jpg';
import landingBg2 from '../../assets/images/section_bg_2.jpg';
import landingBg3 from '../../assets/images/section_bg_3.jpg';
import landingBg4 from '../../assets/images/section_bg_4.jpg';
import landingBg5 from '../../assets/images/section_bg_5.jpg';

import './Landing.scss';
import { Header, Footer, Progress } from '../../components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  history: any;
}

const styles: any = (theme: any) => {
  return ({
    main: {
      marginTop: theme.spacing.unit * 15,
    },
    bgPic: {
      backgroundImage: `url(${topLandingBg})`,
    },
    branding: {
      justifyContent: 'center',
    },
    brandingIcon: {
      fontSize: '100px',
      color: 'red',
    },
    landingSec1: {
      backgroundImage: `url(${landingBg1})`,
    },
    landingSec2: {
      backgroundImage: `url(${landingBg2})`,
    },
    landingSec3: {
      backgroundImage: `url(${landingBg3})`,
    },
    landingSec4: {
      backgroundImage: `url(${landingBg4})`,
    },
    landingSec5: {
      backgroundImage: `url(${landingBg5})`,
    },
  });
};

class Landing extends React.Component<Props, any> {
  public auth = new AuthService();
  constructor(props: Props) {
    super(props);
    this.state = {
      loginError: false,
      showProgress: false,
    };
  }

  private handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  private handleFormSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ showProgress: true });
    this.auth.login(this.state.username, this.state.password)
      .then(res => {
        this.setState({ loginError: false });
        this.props.history.replace('/');
      })
      .catch(err => {
        this.setState({ loginError: true });
      })
      .finally(() => {
        this.setState({ showProgress: false });
      });
  }

  public componentWillMount = () => {
    if (this.auth.loggedIn()) {
      this.props.history.replace('/');
    }
  }

  public render() {
    const { classes } = this.props;
    const { loginError, showProgress } = this.state;

    return (
      <main className='Landing'>
        {showProgress && <Progress />}
        <Header />

        <div className={`div-box top-box ${classes.bgPic}`}>
          <Typography variant='h4' color='inherit'>
            Dauki Course Cikin Harshen Hausa
          </Typography>

          <div className='subtitle'>
            <Typography variant='body1' color='inherit'>
              Yi nazari mai zurfi akan darussa (courses) cikin harshen Hausa, sannan kuma ka sami
              shaidar kammalawa
          </Typography>
          </div>

          <Link to='/register'><Button variant='contained' color='primary'>Fara Daukar Darasi Yanzu</Button></Link>
        </div>

        <div className='branding'>
          <Grid container spacing={24} className={classes.branding}>
            <Grid item xs={12} sm={3}>
              <div className='branding-item'>
                <AlarmIcon className={classes.brandingIcon} />
                <Typography variant='body1' color='inherit'>
                  Zabi daga cikin jerin (fannonin) Courses da muke dasu
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className='branding-item'>
                <PhoneLinkIcon className={classes.brandingIcon} />
                <Typography variant='body1' color='inherit'>
                  Karanci darussa cikin hanyar nishadantarwa da kuma misalai na ban al'ajabi wanda
                  zaisa ka fahimci darasi cikin gamsarwa
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className='branding-item'>
                <PublicIcon className={classes.brandingIcon} />
                <Typography variant='body1' color='inherit'>
                  Dauki darasi a duk inda ka sami kanka ta hanyar amfani da Laptop ko kuma wayar salula
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={`landing-sec ${classes.landingSec2}`}>
          <Grid container spacing={24} className='container'>
            <Grid item xs={12} sm={6} className='items-box'>
              <Typography variant='h4' color='inherit'>
                Dauki darasi a duk inda ka sami kanka
              </Typography>

              <Typography variant='body1' color='inherit'>
                Dauki darasi a duk inda ka sami kanka ta hanyar amfani da Laptop ko kuma wayar salula
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={`landing-sec ${classes.landingSec3}`}>
          <Grid container spacing={24} className='container container-right'>
            <Grid item xs={12} sm={6} className='items-box'>
              <Typography variant='h4' color='inherit'>
                Sami fahimta mai zurfi
              </Typography>

              <Typography variant='body1' color='inherit'>
                Darussan mu an tsarasu ta hanyar zamani da zasu sa mai karatu ya sami fahimta mai zurfi akan darasi
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={`landing-sec ${classes.landingSec4}`}>
          <Grid container spacing={24} className='container'>
            <Grid item xs={12} sm={6} className='items-box'>
              <Typography variant='h4' color='inherit'>
                Sami Shaidar Kammalawa (Certificate)
              </Typography>

              <Typography variant='body1' color='inherit'>
                Idan aka sami nasarar kammala dakkanin darusa, tare da amsa dukkanin Jarabawoyi, za a iya samin shairdar kammalawa
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Footer />
      </main>
    );
  }
}

export default withStyles(styles)(Landing);
