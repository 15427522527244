import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService } from '../../services';

import './Register.scss';
import { Header, Footer, Progress, SnackBar } from '../../components';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  history: any;
}

const styles: any = (theme: any) => {
  return ({
    main: {
      width: 'auto',
      display: 'block',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      marginTop: theme.spacing.unit * 12,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
  });
};

class Register extends React.Component<Props, any> {
  public auth = new AuthService();
  constructor(props: Props) {
    super(props);
    this.state = {
      loginError: false,
      showProgress: false,
      openSnackBar: false,
    };
  }

  private handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  private handleFormSubmit = (e: any) => {
    e.preventDefault();

    this.setState({openSnackBar: true});
    return;
    this.setState({showProgress: true});
    this.auth.login(this.state.username, this.state.password)
      .then(res => {
        this.setState({ loginError: false });
        this.props.history.replace('/');
      })
      .catch(err => {
        this.setState({loginError: true});
      })
      .finally(() => {
        this.setState({showProgress: false});
      });
  }

  public componentWillMount = () => {
    if (this.auth.loggedIn()) {
      this.props.history.replace('/');
    }
  }

  public render() {
    const { classes } = this.props;
    const { loginError, showProgress, openSnackBar } = this.state;

    return (
      <main>
        {showProgress && <Progress />}
        <Header />

        <SnackBar open={openSnackBar} message='registration not enbaled at the moment' />

        <div className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign Up
          </Typography>
            <form className={classes.form}>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input
                  id='email'
                  name='username'
                  type='email'
                  autoComplete='email'
                  autoFocus
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>First Name</InputLabel>
                <Input
                  id='first_name'
                  name='first_name'
                  type='name'
                  autoComplete='first_name'
                  autoFocus
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Last Name</InputLabel>
                <Input
                  id='last_name'
                  name='last_name'
                  type='name'
                  autoComplete='last_name'
                  autoFocus
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <Input
                  name='password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Confirm Password</InputLabel>
                <Input
                  name='confirm_password'
                  type='password'
                  id='confirm_password'
                  onChange={this.handleChange} />
              </FormControl>

              {loginError && <Typography component='p' color='secondary'> email or password is incorrect </Typography>}

              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={this.handleFormSubmit}
              >
                Sign Up
            </Button>
            </form>
          </Paper>
          <Footer />
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(Register);
