import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { AddUnit, AddLesson } from '../../../components/Modals';
import { ICourse, IUnit, IProfile } from '../../../interfaces';
import { AuthService, CourseService } from '../../../services';
import { withAuth } from '../../../components';
import { withStyles } from '@material-ui/core/styles';

import './Course.scss';

const styles = (theme: any) => ({
    // heading: {
    //     fontSize: theme.typowithAuthgraphy.pxToRem(20),
    // },
    root: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    secondaryHeading: {
        // fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    }, button: {
        margin: theme.spacing.unit,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export interface Props {
    children?: React.ReactNode;
    classes: any;
    history: any;
    match: any;
    hideNav: any;
    passData: any;
    showLoader: any;
    hideLoader: any;
}

export interface State {
    course: ICourse;
    profile: IProfile;
}

class Course extends React.Component<Props, State> {
    private Auth = new AuthService();
    private CourseService = new CourseService();
    constructor(props: Props) {
        super(props);

        this.receiveAddedUnit = this.receiveAddedUnit.bind(this);
        this.receiveUpdatedUnit = this.receiveUpdatedUnit.bind(this);
    }

    public componentWillMount() {
        this.setState({ profile: this.Auth.getProfile() });
    }

    private resizeHeaderOnScroll() {
        const scrollTop: any = document.documentElement !== null ? document.documentElement.scrollTop : null;
        const distanceY = window.pageYOffset || scrollTop;
        const headEl = document.getElementsByClassName('course-head')[0];
        // if (distanceY > 120 && headEl) {
        //     headEl.classList.add('smaller');
        // } else if (distanceY < 10 && headEl) {
        //     headEl.classList.remove('smaller');
        // }
    }

    public async componentDidMount() {
        this.props.showLoader();
        try {
            const { match: { params } } = this.props;
            const res = await this.CourseService.getOne(params.id);
            this.setState({ course: res });
            this.props.passData(res);
        } catch (error) {
            throw new Error();
        }
        this.props.hideLoader();
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
    }

    public receiveAddedUnit(unit: IUnit) {
        const { course } = this.state;
        course.units.push(unit);

        this.setState({ course });
    }

    public receiveUpdatedUnit(unit: IUnit) {
        const { course } = this.state;
        const { units } = course;
        units[units.findIndex((obj: IUnit) => obj.id === unit.id)] = unit;
        course.units = units;
        this.setState({ course });
    }

    public render() {
        const { classes } = this.props;
        const { course } = this.state;
        if (!course) {
            return null;
        }

        const { progress } = course;
        return (
            <div className='Course'>
                <Paper className={`${classes.root} course-head`} elevation={1}>

                    <Typography variant='h4' component='h2'>
                        {course.name}
                    </Typography>

                    <div className='description'>
                        {course.description && <Typography component='p'>
                            {course.description}
                        </Typography>}
                    </div>
                    {/* {progress && <LinearProgress variant='buffer'
                        value={(100 / progress.total_lessons) * progress.completed_lessons.length} valueBuffer={0} />}
                    {progress && <Typography component='p'>
                        {(100 / progress.total_lessons) * progress.completed_lessons.length}% Completed
                    </Typography>} */}

                    <div className='add-unit'>
                        <AddUnit course={course} send={this.receiveAddedUnit} />
                    </div>
                </Paper>
                <div className='root-div'>
                    <ol>
                        {course.units.map((unit: any) => (
                            <li key={unit.id}>
                                <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className={classes.column}>
                                            <Typography className={classes.heading}>{unit.name}</Typography>
                                        </div>
                                        <div className={classes.column} />
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.details}>
                                        <div className={classes.column}>
                                            <Typography variant='caption'>
                                                {unit.description}
                                            </Typography>
                                        </div>
                                        <div className={classes.column} />
                                        <div className={classNames(classes.column, classes.helper)}>
                                            <Typography variant='caption'>
                                                {unit.description}
                                            </Typography>
                                        </div>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelActions>
                                        <AddLesson unit={unit} course={course} send={this.receiveUpdatedUnit} />
                                        <AddUnit unit={unit} course={course} send={this.receiveUpdatedUnit} />
                                        <Link className={classes.link} to={`/courses/${course.id}/units/${unit.id}/lessons/${unit.first_lesson}`} style={{ textDecoration: 'none' }}>
                                            <Button variant='outlined' color='primary' className={classes.button}>
                                                Start Unit
                                            </Button>
                                        </Link>
                                    </ExpansionPanelActions>
                                </ExpansionPanel>
                            </li>
                        ))}
                    </ol>

                </div>

            </div>
        );
    }
}

export default withAuth(withStyles(styles)(Course));
