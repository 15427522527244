import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { UnitService } from '../../services';
import { SnackBar, Progress } from '../';
import { ICourse, IUnit } from '../../interfaces';

const styles: any = (theme: any) => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 150,
    },
});

function transition(props: any) {
    return <Slide direction='up' {...props} />;
}

export interface Props {
    children?: React.ReactNode;
    classes: any;
    timer: any;
    course: ICourse;
    unit?: IUnit;
    send: any;
}

class AddUnit extends React.Component<Props, any> {
    private unitService = new UnitService();
    private baseState: any;
    constructor(props: any) {
        super(props);
        const { unit, course } = this.props;
        this.state = {
            name: unit ? unit.name : '',
            description: unit ? unit.description : '',
            order: unit ? unit.order : course.units.length + 2,
            open: false,
            diableButton: false,
            openSnackBar: false,
            showProgress: false,
        };
        this.baseState = this.state;
    }

    public handleOpen = () => {
        this.setState({ open: true });
    }

    public handleClose = () => {
        this.setState(this.baseState);
    }

    private handleChange = (e: any, name: string) => {
        this.setState({ [name]: e.target.value });
    }

    public AddUnit = (event: any) => {
        event.preventDefault();
        const { name, description, order } = this.state;
        const course = this.props.course.id;
        const { unit } = this.props;
        this.setState({ diableButton: true, openSnackBar: false, showProgress: true });

        if (unit) {
            unit.name = name;
            unit.description = description;
            unit.order = order;

            this.unitService
                .update(unit)
                .then(res => {
                    this.props.send(res);
                    this.handleClose();
                })
                // .catch(err => {})
                .finally(() => {
                    this.setState({ diableButton: false, openSnackBar: true, showProgress: false });
                });
        } else {
            this.unitService
                .add({ name, description, order, course })
                .then(res => {
                    this.props.send(res);
                    this.handleClose();
                })
                // .catch(err => {})
                .finally(() => {
                    this.setState({ diableButton: false, openSnackBar: true, showProgress: false });
                });
        }
    }

    public render() {
        const { classes, course, unit } = this.props;
        const { diableButton, openSnackBar, showProgress } = this.state;

        const options = [];
        const end = unit ? course.units.length : course.units.length + 1;
        for (let i = 1; i <= end; i += 1) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        return (
            <div className='AddUnit'>
                <Button onClick={this.handleOpen} variant={unit ? 'outlined' : 'contained'} color={unit ? 'secondary' : 'primary'} className={classes.button}>
                    {!unit && <AddIcon />} { unit ? 'Edit' : 'Unit' }
                </Button>

                <SnackBar open={openSnackBar} message={course ? 'Unit Updated' : 'New Unit Added'} />

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={transition}
                >
                    <form className={classes.container} onSubmit={this.AddUnit}>
                        {showProgress && <Progress />}
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.handleClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='h6' color='inherit' className={classes.flex}>
                                    {course ? 'Update' : 'New'} Unit
                                </Typography>
                                <Button disabled={diableButton} color='inherit' type='submit'>
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            <ListItem>
                                <TextField
                                    required
                                    label='Unit Name'
                                    className={classes.textField}
                                    name='name'
                                    value={this.state.name}
                                    onChange={(e) => this.handleChange(e, 'name')}
                                    autoComplete='name'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    required
                                    label='Unit Description'
                                    name='description'
                                    value={this.state.description}
                                    autoComplete='description'
                                    onChange={(e) => this.handleChange(e, 'description')}
                                    multiline
                                    rows='4'
                                    className={classes.textField}
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <FormControl variant='filled' className={classes.formControl}>
                                    <InputLabel htmlFor='filled-age-native-simple'>Order</InputLabel>
                                    <Select
                                        native
                                        className={classes.textField}
                                        value={this.state.order}
                                        onChange={(e) => this.handleChange(e, 'order')}
                                        input={<FilledInput name='order' id='filled-age-native-simple' />}
                                    >
                                        <option value='' />
                                        {options}
                                    </Select>
                                </FormControl>
                            </ListItem>
                        </List>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddUnit);
