import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withRouter } from 'react-router';
import { AuthService } from '../../services';
import { RouteComponentProps, Link } from 'react-router-dom';
import red from '@material-ui/core/colors/red';
import { Button } from '@material-ui/core';

const styles: any = (theme: any) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgb(255,255,255)',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    color: '#333',
    boxShadow: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  loginBtn: {
    marginRight: theme.spacing.unit * 3,
    borderRight: '1px solid rgba(0,0,0,.12)',
  },
});

export interface Props extends RouteComponentProps<any> {
  children?: React.ReactNode;
  classes: any;
}

class Header extends React.Component<Props, any> {
  private Auth = new AuthService();

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }

  private handleProfileMenuOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  }

  private gotoProfile = () => {
    this.setState({ anchorEl: null });
    this.props.history.push('/profile');
  }

  private handleMobileMenuOpen = (event: any) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  }

  private handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  }

  private handleLogout = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
    this.Auth.logout();
    this.props.history.replace('/login');
  }

  public render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const color = red['A400'];

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.gotoProfile}>Profile</MenuItem>
        <MenuItem onClick={this.handleLogout}>Log out</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {this.Auth.getRefresh() ?
          <span>
            <MenuItem onClick={this.gotoProfile}>Profile</MenuItem>
            <MenuItem onClick={this.handleLogout}>Log out</MenuItem>
          </span>
          :
          <span>
            <MenuItem><Link to='/login'>Log In</Link></MenuItem>
            <MenuItem><Link to='/register'>Sign Up</Link></MenuItem>
          </span>}
      </Menu>
    );

    return (
      <div>
        <AppBar position='fixed' className={classes.root}>
          <Toolbar>
            {/* <IconButton className={classes.menuButton} color='inherit' aria-label='Open drawer'>
              <MenuIcon />
            </IconButton> */}
            <Link to='/'>
              <Typography className={classes.title} variant='h6' color='inherit' noWrap>
                koyi atakaice
            </Typography>
            </Link>
            <div className={classes.search}>
              <Link to='/courses'>
                <Button variant='contained' color='primary'>
                  Duba Darussa
              </Button>
              </Link>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {!this.Auth.getRefresh() && <Link to='/login'> <Button className={classes.loginBtn} style={{ textTransform: 'capitalize' }}>Log In</Button> </Link>}

              {this.Auth.getRefresh() ?
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup='true'
                  onClick={this.handleProfileMenuOpen}
                  color='inherit'
                >
                  <AccountCircle />
                </IconButton> :
                <Link to='/register'><Button variant='contained' color='primary'>Sign Up</Button></Link>
              }
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup='true' onClick={this.handleMobileMenuOpen} color='inherit'>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Header));
