import React from 'react';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import Menu from '@material-ui/core/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Switch, Route, Link, withRouter } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';

import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';

import { AuthService, UnitService } from '../../services';
import { withAuth, Progress } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { Lesson } from '../';
import grayBgImage from '../../assets/images/gray_bg.jpg';

import './Unit.scss';
import { IProfile } from '../../interfaces';
import Loader from './Loader';

const drawerWidth = 300;

const styles: any = (theme: any) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    // backgroundImage: `url(${grayBgImage})`,
    // color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overFlowY: 'auto',
  },
  myTheme: {
    backgroundImage: `url(${grayBgImage})`,
    color: '#fff',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    padding: theme.spacing.unit * 3,
    backgroundColor: '    rgb(255, 255, 255)',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

export interface Props {
  children?: React.ReactNode;
  classes: any;
  history: any;
  match: any;
  hideNav: any;
  course: any;
  profile: IProfile;
}

export interface State {
  profile: IProfile;
  open: boolean;
  unit: any;
  anchorEl: any;
  mobileMoreAnchorEl: any;
}

class Unit extends React.Component<Props, any> {
  private Auth = new AuthService();
  private UnitService = new UnitService();
  constructor(props: Props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      unit: null,
      open: true,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      showLoader: false,
    };
  }

  public componentWillMount() {
    this.setState({ profile: this.Auth.getProfile() });
  }

  public async componentDidMount() {
    this.props.hideNav();
    const { match: { params: { unitId } } } = this.props;
    const res = await this.UnitService.getOne(unitId);
    this.setState({ unit: res });

    const appBar = document.getElementsByClassName('app-bar')[0];
    const content = document.getElementsByClassName('content')[0];

    if (appBar && content) {
      this.setState({ containerMargin: appBar.clientHeight });
    }
  }

  private handleDrawerOpen = () => {
    this.setState({ open: true });
  }

  private handleDrawerClose = () => {
    this.setState({ open: false });
  }

  private handleProfileMenuOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  }

  private handleLogout = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
    this.Auth.logout();
    this.props.history.replace('/login');
  }

  private gotoProfile = () => {
    this.setState({ anchorEl: null });
    this.props.history.push('/profile');
  }

  private handleMobileMenuOpen = (event: any) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  }

  private handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  }

  private showLoader = () => {
    this.setState({ showLoader: true });
  }

  private hideLoader = () => {
    this.setState({ showLoader: false });
  }

  public render() {
    const { anchorEl, mobileMoreAnchorEl, containerMargin, showLoader, unit } = this.state;
    const { classes, match } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    let mainListItems = null;
    let course = null;
    let lessons: any = null;
    let progress: any = null;

    if (unit) {
      course = unit.course;
      lessons = unit.lessons;
      progress = unit.progress;
      // const { completed_lessons, total_lessons } = progress;

      mainListItems = lessons.map((item: any) => (
        <Link to={`${match.url}/lessons/${item.id}`} key={item.id} style={{ textDecoration: 'none' }}>
          <ListItem button id={`link-${item.id}`} style={{ maxHeight: '70px' }}>
            <ListItemIcon>
              {progress && progress.completed_lessons.includes(item.id) ? <DoneAll /> : <Done />}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        </Link>
      ));
    }


    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.gotoProfile}>Profile</MenuItem>
        <MenuItem onClick={this.handleLogout}>Log Out</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color='inherit'>
            <Badge badgeContent={4} color='secondary'>
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem onClick={this.handleMobileMenuClose}>
          <IconButton color='inherit'>
            <Badge badgeContent={11} color='secondary'>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleMobileMenuOpen}>
          <IconButton color='inherit'>
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={`${classes.root} Unit`}>
        <CssBaseline />
        {unit &&
          <AppBar
            color='primary'
            position='fixed'
            className={classNames(classes.appBar, this.state.open && classes.appBarShift, 'app-bar')}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component='h1'
                variant='h6'
                color='inherit'
                noWrap
                className={classes.title}
              >
                {`${course.name} : ${unit.name}`}
              </Typography>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup='true'
                onClick={this.handleProfileMenuOpen}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>}
        {course &&
          <Drawer
            variant='permanent'
            className='drawer'
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <div style={{ width: '80%' }}>
                <Link to={`/courses/${course.id}`} style={{ float: 'left' }}>
                  <ChevronLeftIcon />
                </Link>
              </div>
              <IconButton onClick={this.handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <div>
              <List>{mainListItems}</List>
            </div>
          </Drawer>}
          {showLoader && <Progress />}
        <div className={`${classes.content} content`} style={{ marginTop: containerMargin }}>
          {unit ?
              <Route path={`${match.path}/lessons/:lessonId`} render={(props) =>
                <Lesson lessons={lessons} showLoader={this.showLoader} hideLoader={this.hideLoader} {...props} />} /> :
              <Loader />
          }
        </div>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

export default withAuth(withStyles(styles)(Unit));
