import decode from 'jwt-decode';
import axios from 'axios';
import config from '../utils/config';
import { ApiService, StorageService } from './';
import { ICourse } from '../interfaces';

const { API_ENDPOINT } = config;

export default class CourseService {
    // Initializing important variables
    private API = new ApiService();
    private URL = `${API_ENDPOINT}/courses/`;

    public async getOne(id: number): Promise<ICourse> {
        const res = await this.API.client.get(`${this.URL}${id}/`);
        return Promise.resolve(res);
    }

    public async getPreview(id: number): Promise<ICourse> {
        const res = await this.API.client.get(`${this.URL}${id}/preview/`);
        return Promise.resolve(res);
    }

    public async getAll(page: number = 1): Promise<any> {
        const res = await this.API.client.get(`${this.URL}?page=${page < 1 ? 1 : page}`);
        return Promise.resolve(res);
    }

    public async add(payload: any): Promise<ICourse> {
        const res = await this.API.client
            .post(this.URL, payload);
        return Promise.resolve(res);
    }

    public async update(payload: ICourse): Promise<ICourse> {
        const res = await this.API.client
            .put(`${this.URL}${payload.id}/`, payload);
        return Promise.resolve(res);
    }
}
