import config from '../utils/config';
import { ApiService } from './';
import { IUnit } from '../interfaces';

const { API_ENDPOINT } = config;

export default class UnitService {
    // Initializing important variables
    private API = new ApiService();
    private URL = `${API_ENDPOINT}/units/`;

    public async getOne(id: number): Promise<IUnit> {
        const res = await this.API.client.get(`${this.URL}${id}/`);
        return Promise.resolve(res);
    }

    public async getAll(page: number = 1): Promise<any> {
        const res = await this.API.client.get(`${this.URL}?page=${page < 1 ? 1 : page}`);
        return Promise.resolve(res);
    }

    public async add(payload: any): Promise<IUnit> {
        const res = await this.API.client.post(this.URL, payload);
        return Promise.resolve(res);
    }

    public async update(payload: IUnit): Promise<IUnit> {
        const res = await this.API.client
            .put(`${this.URL}${payload.id}/`, payload);
        return Promise.resolve(res);
    }
}
