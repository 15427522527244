import React from 'react';
import { RouteComponentProps, withRouter, Redirect, Link } from 'react-router-dom';
import { Dictionary, ILecture, ILesson } from '../../interfaces';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import './Lecture.scss';
import { ProgressService } from '../../services';

const styles = (theme: any) => ({
  margin: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

export interface Props extends RouteComponentProps<any> {
  children?: React.ReactNode;
  lecture: ILecture;
  classes: any;
  lesson: ILesson;
  lessons?: any;
}

class Lecture extends React.Component<Props, any> {
  private ps = new ProgressService();
  constructor(props: Props) {
    super(props);

    this.state = {
      containerHeight: 300,
      openUnitCompleted: false,
      currentCount: 5,
      openUnitComplete: false,
    };

    this.proceed = this.proceed.bind(this);
  }

  public componentDidMount() {
    const container = document.getElementsByClassName('_lecture-block ')[0];

    if (container) {
      this.setState({ containerHeight: container.clientHeight + 5 });
    }
  }

  private proceed = async () => {
    const { lesson: { id, unit, course } } = this.props;
    const payload = { unit_id: unit, course_id: course, lesson_id: id };

    try {
      const res = await this.ps.proceed(payload);
      console.log(res);
      if (res.id) {
        let wait = 0;
        if (res.unit !== unit) {
          this.setState({ openUnitComplete: true });
          return;
          wait = 5000;
          setInterval(() => {
            this.setState({ currentCount: this.state.currentCount + 1 });
          }, 1000);
        }
        // dismiss unit completed dialog
        setTimeout(() => {
          this.setState({ openUnitCompleted: false });
          this.props.history.push(`/courses/${res.course}/units/${res.unit}/lessons/${res.id}`);
        }, wait);
      } else {
        // end of the course
        this.setState({ openUnitComplete: true });
        return;
      }
    } catch (err) {
      // error msg
      console.log(err);
    }
  }

  private handleClose = () => {
    this.setState({ openUnitCompleted: !this.state.openUnitCompleted, openUnitComplete: false });
    this.props.history.push(`/courses/${this.props.lesson.course}`);
  }

  public render() {
    const { containerHeight, openUnitCompleted, currentCount, openUnitComplete } = this.state;
    const { classes, lecture, lessons, lesson } = this.props;
    const { lecture_type, html, video, picture, name } = lecture;
    const videoSrc = lecture_type === 'VID' ? `https://www.youtube.com/embed/${video}?autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1` : undefined;
    const style = { padding: '3%', border: '1px solid #dbe2e8' };
    if (lecture_type === 'VID') {
      style.padding = '0';
      style.border = 'none';
    }

    let prev_lesson;
    try {
      prev_lesson = lessons[lessons.map((e: any) => e.id).indexOf(lesson.id) - 1];
    } catch {
      prev_lesson = undefined;
    }

    return (
      <div className='Lecture'>

        <Dialog
          open={openUnitCompleted}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>

          <DialogTitle id='alert-dialog-title' color='secondary'>
            <Typography variant='h2' color='inherit'>
              {currentCount}
            </Typography>
          </DialogTitle>

        </Dialog>

        <Dialog
          open={openUnitComplete}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>

          <DialogTitle id='alert-dialog-title' color='secondary'>Jinjina!!!</DialogTitle>

          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Madalla da samin nasarar kammala darussan wannan shashe (Unit)
          </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose} color='primary' autoFocus>
              Kammala
            </Button>
          </DialogActions>
        </Dialog>

        <div className='_lecture-block' style={style}>
          {lecture_type === 'HTML' && html &&
            <div className='_html' dangerouslySetInnerHTML={{ __html: html }} />}

          {lecture_type === 'PIC' && picture &&
            <div className='_pic'>
              <img src={picture} alt={name} />
            </div>}

          {lecture_type === 'VID' && video && containerHeight &&
            <div className='_vid'>
              <iframe className='player' width='100%' height={containerHeight} src={videoSrc} />
            </div>}
        </div>

        <div className='submit'>
          {prev_lesson &&
            <Link to={`/courses/${prev_lesson.course}/units/${prev_lesson.unit}/lessons/${prev_lesson.id}`}>
              <Button color='primary' style={{ float: 'left' }} className={classes.button}>
                Koma baya
              </Button>
            </Link>}

          <Button variant='contained' size='large' color='primary' style={{ float: 'right' }} className={classes.margin} onClick={this.proceed}>
            Ci gaba
            </Button>
        </div>

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Lecture));
