import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { UnitService, ProfileService } from '../../services';
import { SnackBar, Progress } from '../';
import { ICourse, IUnit } from '../../interfaces';
import { validate } from '../../utils';

const styles: any = (theme: any) => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 150,
    },
});

function transition(props: any) {
    return <Slide direction='up' {...props} />;
}

export interface Props {
    children?: React.ReactNode;
    classes: any;
    profile: any;
    send: any;
}

class UpdateProfile extends React.Component<Props, any> {
    private profileService = new ProfileService();
    constructor(props: any) {
        super(props);
        const { profile } = this.props;
        this.state = {
            username: profile.username,
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            location: profile.location,
            tagline: profile.tagline,
            phone: profile.phone,
            dob: profile.dob,
            gender: profile.gender,
            open: false,
            errorMsg: '',
            diableButton: false,
            openSnackBar: false,
            showProgress: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    public handleOpen = () => {
        this.setState({ open: true });
    }

    public handleClose = () => {
        this.setState({disableButton: false, open: false });
    }

    private handleChange(e: any, name: string) {
        this.setState({ [name]: e.target.value });
    }

    public save = (event: any) => {
        event.preventDefault();
        this.setState({ diableButton: true, openSnackBar: false, showProgress: true });
        const { first_name, last_name, email, location, tagline, phone, dob, gender } = this.state;
        const payload = { first_name, last_name, email, location, tagline, phone, dob, gender };

        let error = false;
        let message = 'Provide a valid';

        if (phone && !validate.phone(phone)) {
            error = true;
            message = `${message} phone number`;
        }

        if (!validate.email(email)) {
            if (error) {
                message = `${message} and email`;
            } else {
                message = `${message} email`;
            }
            error = true;
        }

        if (error) {
            this.setState({errorMsg: message, openSnackBar: true});
            setTimeout(() => {
                this.setState({ errorMsg: '', diableButton: false, openSnackBar: false, showProgress: false });
            }, 1500);
            return;
        }

        this.profileService
                .update(payload)
                .then(res => {
                    this.props.send(res);
                })
                .catch(err => {
                    this.setState({ disableButton: false });
                })
                .finally(() => {
                    this.setState({ openSnackBar: true, showProgress: false, diableButton: false });
                });

        setTimeout(() => {
            this.handleClose();
        }, 1500);
    }

    public render() {
        const { classes } = this.props;
        const { username,
                first_name,
                last_name,
                email,
                location,
                tagline,
                phone,
                dob,
                gender,
                open,
                diableButton,
                openSnackBar,
                errorMsg,
                showProgress } = this.state;

        return (
            <div className='UpdateProfile'>
                <Button onClick={this.handleOpen} variant='outlined' color='secondary' className={classes.button}>
                    <EditIcon /> Edit Profile
                </Button>

                <SnackBar open={openSnackBar} message={errorMsg ? errorMsg : 'Profile Updated'} />

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={transition}>
                    <form className={classes.container} onSubmit={this.save}>
                        {showProgress && <Progress />}
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.handleClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='h6' color='inherit' className={classes.flex}>
                                    Update Profile
                                </Typography>
                                <Button disabled={diableButton} color='inherit' type='submit'>
                                    <SaveIcon/>{`  Save`}
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            <ListItem>
                                <TextField
                                    required
                                    label='First Name'
                                    className={classes.textField}
                                    name='first_name'
                                    value={first_name}
                                    onChange={(e) => this.handleChange(e, 'first_name')}
                                    autoComplete='first_name'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    required
                                    label='Last Name'
                                    className={classes.textField}
                                    name='last_name'
                                    value={last_name}
                                    onChange={(e) => this.handleChange(e, 'last_name')}
                                    autoComplete='last_name'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    required
                                    label='Email'
                                    className={classes.textField}
                                    name='email'
                                    value={email}
                                    type='email'
                                    onChange={(e) => this.handleChange(e, 'email')}
                                    autoComplete='email'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label='Tagline'
                                    className={classes.textField}
                                    name='tagline'
                                    value={tagline}
                                    onChange={(e) => this.handleChange(e, 'tagline')}
                                    autoComplete='tagline'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label='Phone Number'
                                    className={classes.textField}
                                    name='phone'
                                    type='phone'
                                    value={phone}
                                    onChange={(e) => this.handleChange(e, 'phone')}
                                    autoComplete='phone'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label='Date of Birth'
                                    type='date'
                                    className={classes.textField}
                                    name='dob'
                                    value={dob}
                                    onChange={(e) => this.handleChange(e, 'dob')}
                                    autoComplete='date of birth'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label='Location'
                                    className={classes.textField}
                                    name='location'
                                    value={location}
                                    onChange={(e) => this.handleChange(e, 'location')}
                                    autoComplete='location'
                                    margin='normal'
                                    variant='filled'
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <FormControl variant='filled' className={classes.formControl}>
                                    <InputLabel htmlFor='filled-age-native-simple'>Gender</InputLabel>
                                    <Select
                                        native
                                        className={classes.textField}
                                        value={gender}
                                        onChange={(e) => this.handleChange(e, 'gender')}
                                        input={<FilledInput name='gender' id='filled-age-native-simple' />}
                                    >
                                        <option value='' />
                                        <option value='M'> Male </option>
                                        <option value='F'> Female </option>
                                    </Select>
                                </FormControl>
                            </ListItem>
                        </List>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(UpdateProfile);
