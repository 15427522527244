import React from 'react';
import * as PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService } from '../../services';

import './Login.scss';
import { Header, Footer, Progress } from '../../components';

export interface Props {
  children?: React.ReactNode;
  classes: any;
  history: any;
  showLoader: any;
  hideLoader: any;
  hideNav: any;
}

const styles: any = (theme: any) => {
  return ({
    main: {
      width: 'auto',
      display: 'block',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      marginTop: theme.spacing.unit * 7,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
  });
};

class Login extends React.Component<Props, any> {
  public auth = new AuthService();
  constructor(props: Props) {
    super(props);
    this.state = {
      loginError: false,
      showProgress: false,
      disableButton: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  private handleChange(e: any) {
    if (e.target.value === 'remember') {
      this.setState({ 'remember': e.target.checked });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });
  }

  private handleFormSubmit(e: any) {
    e.preventDefault();
    this.setState({disableButton: true, showProgress: true});
    this.auth.login(this.state.username, this.state.password)
      .then(res => {
        this.setState({ loginError: false });
        this.props.hideLoader();
        this.props.history.replace('/');
      })
      .catch(err => {
        this.setState({ loginError: true });
        this.props.hideLoader();
      }).finally(() => {
        this.setState({showProgress: false, disableButton: false});
      });
  }

  public componentWillMount = () => {
    if (this.auth.loggedIn()) {
      this.props.history.replace('/');
    }
  }

  public render() {
    const { classes } = this.props;
    const { loginError, disableButton, showProgress } = this.state;

    return (
      <main className='Login'>
        <Header />
        {showProgress && <Progress />}
        <div className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
          </Typography>
            <form className={classes.form} onSubmit={this.handleFormSubmit}>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input
                  id='email'
                  name='username'
                  type='email'
                  autoComplete='email'
                  autoFocus
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <Input
                  name='password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  onChange={this.handleChange} />
              </FormControl>

              <FormControlLabel
                control={<Checkbox
                  value='remember'
                  color='primary'
                  onChange={this.handleChange} />}
                label='Remember me'
              />

              {loginError && <Typography component='p' color='secondary'> email or password is incorrect </Typography>}

              <Button
                type='submit'
                fullWidth
                disabled={disableButton}
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Sign in
            </Button>
            </form>
          </Paper>
          <Footer />
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(Login);
