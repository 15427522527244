import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = (props: any) => {
  return (
    <ContentLoader
		rtl
		height={350}
		width={700}
		speed={2}
		primaryColor='#f3f3f3'
		secondaryColor='#ecebeb'
		{...props}
	>
		<rect x='49.78' y='55.61' rx='0' ry='0' width='0' height='0' />
		<rect x='27.53' y='18.61' rx='0' ry='0' width='644.09' height='146' />
		<rect x='26.63' y='199.61' rx='0' ry='0' width='649' height='146' />
	</ContentLoader>
  );
};

export default Loader;
