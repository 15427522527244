import config from '../utils/config';
import { ApiService } from '.';
import { ILesson } from '../interfaces';

const { API_ENDPOINT } = config;

export default class ProgressService {
    // Initializing important variables
    private API = new ApiService();
    private URL = `${API_ENDPOINT}/`;

    public async proceed(payload: any): Promise<any> {
        const res = await this.API.client.post(`${this.URL}update-progress/`, payload);
        return Promise.resolve(res);
    }

    public async submitAnswer(payload: any): Promise<any> {
        const res = await this.API.client.post(`${this.URL}answer-question/`, payload);
        return Promise.resolve(res);
    }
}
