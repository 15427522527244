import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = (props: any) => {
	return (
		<ContentLoader
		rtl
		height={360}
		width={700}
		speed={1}
		primaryColor='#f3f3f3'
		secondaryColor='#ecebeb'
		{...props}
	>
		<rect x='113.73' y='46.61' rx='0' ry='0' width='145.84' height='148' />
		<rect x='111.63' y='240.61' rx='0' ry='0' width='158' height='12' />
		<rect x='109.63' y='272.61' rx='0' ry='0' width='161' height='8' />
		<rect x='328.63' y='51.61' rx='0' ry='0' width='226' height='26' />
		<rect x='334.63' y='90.61' rx='0' ry='0' width='149' height='6' />
		<rect x='334.63' y='195.61' rx='0' ry='0' width='226' height='15' />
		<rect x='338.63' y='234.61' rx='0' ry='0' width='225' height='11' />
		<rect x='338.63' y='267.61' rx='0' ry='0' width='239' height='8' />
		<rect x='335.63' y='286.61' rx='0' ry='0' width='250' height='9' />
		<rect x='340.63' y='311.61' rx='0' ry='0' width='256' height='12' />
		<rect x='335.63' y='144.61' rx='0' ry='0' width='175' height='12' />
	</ContentLoader>
	);
};

export default Loader;
