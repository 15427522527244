import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { IUnit, ICourse, ILesson } from '../interfaces';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { CardActions } from '@material-ui/core';
import { AddLesson } from './Modals';

const styles: any = (theme: any) => ({
    paper: {
        height: 165,
        width: 170,
        padding: theme.spacing.unit * 2,
    },
    divider: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
    container: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    item: {
        width: 'auto',
    },
});

const SortableItem = withStyles(styles)(SortableElement((props: any) => {
    const { editLesson } = props;

    return (
    <ListItem className={props.classes.item}>
        <Grid key={props.lesson.id} item>
            <Paper className={props.classes.paper}>
                <Typography variant='h5' component='h3'>
                    {props.lesson.name}
                </Typography>
                <Divider className={props.classes.divider} />
                <Typography component='p'>
                    {props.lesson.description}
                </Typography>
                <CardActions>
                    <Link to={`/courses/${props.lesson.course}/units/${props.lesson.unit}/lessons/${props.lesson.id}`}>
                        <Button size='small' color='primary'>
                            View
                        </Button>
                    </Link>
                    <Button size='small' variant='contained' color='primary' onClick={() => { console.log('there'); editLesson(props.lesson); }}>
                        Edit
                    </Button>
                </CardActions>
            </Paper>
        </Grid>
    </ListItem>);
}));

const SortableList = withStyles(styles)(SortableContainer((props: any) => {
    const { lessons, editLesson } = props;

    return (
        <List className={props.classes.container}>
            {lessons.map((value: ILesson, index: number) => (
                <SortableItem key={`item-${index}`} lesson={value} editLesson={editLesson} sortIndex={value.order} index={index} />
            ))}
        </List>
    );
}));

interface Props {
    unit: IUnit;
    course: ICourse;
    lessons: ILesson[];
    editLesson: any;
}

export default class SortableComponent extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        const { lessons } = this.props;
        this.state = { lessons };
    }

    public onSortEnd = (indexes: any) => {
        const { oldIndex, newIndex } = indexes;
        const { lessons } = this.state;
        this.setState({ lessons: arrayMove(lessons, oldIndex, newIndex) });
    }

    public render() {
        return <SortableList lessons={this.state.lessons} onSortEnd={this.onSortEnd} axis='x' editLesson={this.props.editLesson} />;
    }
}
