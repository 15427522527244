import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    childred?: any;
    message?: string;
}

export default class Alert extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: true,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    private handleClickOpen() {
        this.setState({open: true});
    }

    private handleClose() {
        this.setState({open: false});
    }

    public render() {
        const { open } = this.state;
        const { message } = this.props;

        return (
            <div>

                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'>

                    <DialogTitle id='alert-dialog-title'>{message}</DialogTitle>

                    {/* <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            {message}
                        </DialogContentText>
                    </DialogContent> */}

                    <DialogActions>
                        <Button onClick={this.handleClose} color='primary' autoFocus>
                            Try Again
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }
}
