import React from 'react';
import { AuthService, CourseService } from '../../services';
import { withAuth, Header, Footer } from '../../components';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { AddCourse } from '../../components/Modals';

import './Admin.scss';
import { Link } from 'react-router-dom';
import { ICourse } from '../../interfaces';

const styles: any = (theme: any) => ({
    paper: {
        minHeight: 165,
        width: 170,
        padding: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    divider: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
});
export interface Props {
    children?: React.ReactNode;
    classes: any;
    history: any;
    showLoader: any;
    hideLoader: any;
}

class Admin extends React.Component<Props, any> {
    private Auth = new AuthService();
    private CourseService = new CourseService();
    constructor(props: Props) {
        super(props);
        this.state = {
            profile: this.Auth.getProfile(),
            courses: [],
        };

        this.receiveAddedCourse = this.receiveAddedCourse.bind(this);
    }

    public async componentDidMount() {
        this.props.showLoader();
        const res = await this.CourseService.getAll();
        this.setState({ courses: res.results });
        this.props.hideLoader();
    }

    public receiveAddedCourse(course: ICourse) {
        const courses = this.state.courses;
        courses.push(course);
        this.setState({ courses });
    }

    private receiveEditedCourse = (course: ICourse) => {
        const { courses } = this.state;
        courses[courses.findIndex((obj: ICourse) => obj.id === course.id)] = course;
        this.setState({ courses });
    }

    public render() {
        const { classes } = this.props;
        const { profile, courses } = this.state;

        return (
            <main className={classes.admin}>
                <Header />
                <div className='Admin'>
                    <div className='add-course-box'>
                    <AddCourse send={this.receiveAddedCourse} />
                    </div>

                    <Grid container className={`${classes.root} grid-container`} spacing={16}>
                        <Grid item xs={12}>
                            <Grid container className={classes.demo} justify='center' spacing={40}>
                                {courses.map((c: ICourse) => (
                                    <Grid key={c.id} item>
                                        <Paper className={classes.paper}>
                                            <Typography variant='h5' component='h3'>
                                                <Link to={`/admin/courses/${c.id}`}>{c.name}</Link>
                                            </Typography>
                                            <Divider className={classes.divider} />
                                            <Typography component='p'>
                                                {c.short_description}
                                            </Typography>
                                            <AddCourse course={c} variant='text' color='secondary' send={this.receiveEditedCourse}/>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </main>
        );
    }
}

export default withAuth(withStyles(styles)(Admin));
