import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Home,
        Login,
        Course,
        Unit,
        Admin,
        Profile,
        Register,
        Landing,
        PreviewCourse,
        Catalog } from '../containers';
import { ICourse } from '../interfaces';
import { AuthService } from '../services';

import './App.scss';
import { AdminCourse } from '../containers/Admin/Course';

class App extends React.Component<any, any> {
  private Auth = new AuthService();
  public state = {
    showLoader: false,
    hideNav: false,
    course: null,
    profile: null,
  };

  constructor(props: any) {
    super(props);
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.passData = this.passData.bind(this);
    this.hideNav = this.hideNav.bind(this);
  }

  private showLoader() {
    this.setState({showLoader: true});
  }

  private hideLoader() {
    this.setState({showLoader: false});
  }

  private hideNav() {
    this.setState({hideNav: true});
  }

  private passData(course: ICourse) {
    this.setState({course});
  }

  public render() {
    const loaderFuncs: any = {
      showLoader: this.showLoader,
      hideLoader: this.hideLoader,
      passData: this.passData,
      hideNav: this.hideNav,
      history: this.props.history,
      course: this.state.course,
      profile: this.state.profile,
    };
    return (
      <div className='App'>
        {/* render app */}
        <main className='Main'>
          <Switch>
            {/* all app routes */}
            <Route path='/courses/:courseId/units/:unitId' render={(props) => <Unit {...loaderFuncs} {...props} />} />
            <Route path='/courses/:id/preview' render={(props) => <PreviewCourse {...loaderFuncs} {...props} />} />
            <Route path='/courses/:id' render={(props) => <Course {...loaderFuncs} {...props} />} />
            <Route path='/courses' render={(props) => <Catalog {...loaderFuncs} {...props} />} />
            <Route path='/admin/courses/:id' render={(props) => <AdminCourse {...loaderFuncs} {...props} />} />
            <Route path='/admin' render={(props) => <Admin {...loaderFuncs} {...props} />} />
            <Route path='/profile' render={(props) => <Profile {...loaderFuncs} {...props} />} />
            <Route path='/register' render={(props) => <Register {...loaderFuncs} {...props} />} />
            <Route path='/login' render={(props) => <Login {...loaderFuncs} {...props} />} />

            {(this.Auth.loggedIn() || this.Auth.getRefresh()) ?
             <Route path='*' render={(props) => <Home {...loaderFuncs} {...props} />} /> :
             <Route path='*' render={(props) => <Landing {...loaderFuncs} {...props} />} />
             }
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
