import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = (props: any) => {
  return (
    <ContentLoader
		rtl
		height={350}
		width={700}
		speed={2}
		primaryColor='#f3f3f3'
		secondaryColor='#ecebeb'
		{...props}
	>
		<rect x='299.63' y='79.61' rx='0' ry='0' width='0' height='0' />
		<rect x='226.73' y='41.61' rx='0' ry='0' width='261.89' height='27' />
		<rect x='185.63' y='99.61' rx='0' ry='0' width='361' height='32' />
		<rect x='304.63' y='160.61' rx='0' ry='0' width='0' height='0' />
		<rect x='325.63' y='157.61' rx='0' ry='0' width='96' height='24' />
		<rect x='126.63' y='262.61' rx='0' ry='0' width='500' height='71' />
	</ContentLoader>
  );
};

export default Loader;
